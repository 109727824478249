import React from 'react'
import image1 from "../../assets/marie.jpg"
import image2 from "../../assets/coah.JPG"
import tick from "../../assets/tick.png"
import "./why.css"
export default function Why() {

  return (
    <>
    <div className="locscoll">
    <div className="left-r">
    <img src={image1} alt="" />
    <img src={image2} alt="" />
    <img src={image2} alt="" />
    <img src={image1} alt="" />
  </div>
  <div className="right-r" >
  <span>Tournoi des Go</span>
  <div>
    <span className='stroke-text'>Pourquoi</span>
    <span>participer?</span>
  </div>
  <div className="details-r">
    <div>
      <img src={tick} alt="" />
      <span>Travail</span>
    </div>
    <div>
      <img src={tick} alt="" />
      <span>Discipline</span>
    </div>
    <div>
      <img src={tick} alt="" />
      <span>Succés</span>
    </div>
    <div>
      <img src={tick} alt="" />
      <span>Reussite</span>
    </div>
  </div>
 
        
  </div>
  </div>
  </>

  )
}
