import React, {useState} from 'react'
import { programsData } from '../../../src/data/programsData';
// import "../homeSection/programmes"




export default function Programmes() {

  // State to track clicked category index
  const [clickedIndex, setClickedIndex] = useState(-1);

  const handleCategoryClick = (index) => {
    // Toggle the clicked state of the category
    setClickedIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };


  return (
    <div className="program-categories">
    {programsData.map((program, index)=>(
//  <div className="category" key={index}>
<div
className={`category ${clickedIndex === index ? 'active' : ''}`}
key={index}
onClick={() => handleCategoryClick(index)} // Handle click event
>
  {program.image}
  <span>{program.heading}</span>
  <span>{program.details}</span>
 </div>
 
   )   )

    }
  </div>
  )
}
