
import part1 from "../../src/assets/part1.jpeg";
import careforall from "../../src/assets/part2.jpeg";
import metyBeauty from "../../src/assets/part3.jpeg";
import JusteMesDroits from "../../src/assets/part4.jpeg";
import manbassa from "../../src/assets/manbassa.jpg";
import CoachingMentalPerform from "../../src/assets/Coaching_mental logo.jpeg";
import DunkMagazine from "../../src/assets/Dunk Magazine.jpeg";
import part8 from "../../src/assets/logo-sports-around-the-world.png";
import part9 from "../../src/assets/Mfandena Hotel.png";
import part10 from "../../src/assets/LBC.jpg";
import part11 from "../../src/assets/Ligue du Mfoundi.jpeg";
import part12 from "../../src/assets/P2BoRI.jpg";
import fecabasket from "../../src/assets/fecabasket.jpg";
import mobl from "../../src/assets/mobl.jpeg";
import part15 from "../../src/assets/niternationalGrowth.jpg";
import part16 from "../../src/assets/virginTravel.png";
import elango from "../../src/assets/elango.jpg"
import jaf from "../../src/assets/jaf.jpg"
import pan from "../../src/assets/PAN.png"


export const Partenaire = [
    {
        id: 1,
        img: fecabasket,
        name: 'Fecabasket',
        description: 'Fecabasket: association, chargée d’organiser, de diriger et de développer le basket-ball au Cameroun.',
        socialLinks: {
            facebook: 'https://facebook.com/fecabasket',
            twitter: 'https://twitter.com/fecabasket',
            youtube: 'https://www.youtube.com/@fecabasketofficiel1201/featured',
            instagram: 'https://instagram.com/fecabasket',
        },
    },
    {
        id: 2,
        img: mobl,
        name: 'MOBL-TRANSFER',
        description: "MOBL est une agence de transfert d'argent vers multiples destinations dans le monde.",
        socialLinks: {
            facebook: 'https://facebook.com/mobl',
            twitter: 'https://twitter.com/mobl',
            youtube: 'https://youtube.com/mobl',
            instagram: 'https://instagram.com/mobl',
        },
    },
    {
        id: 3,
        img: careforall,
        name: 'Care 4 All',
        description: "œuvrer en priorité pour les personnes vulnérables et dans les activités sportives des jeunes.",
        socialLinks: {
            facebook: 'https://facebook.com/careforall',
            twitter: 'https://twitter.com/careforall',
            youtube: 'https://www.youtube.com/channel/UCzAbbPgd1mwza0CTk2N9B5Q',
            instagram: 'https://instagram.com/careforall',
        },
    },
    {
        id: 4,
        img: metyBeauty,
        name: 'Mety Beauty',
        description: 'Mety Beauty.',
        socialLinks: {
            facebook: 'https://facebook.com/metybeauty',
            twitter: 'https://twitter.com/metybeauty',
            youtube: 'https://youtube.com/metybeauty',
            instagram: 'https://instagram.com/metybeauty',
        },
    },
    {
        id: 5,
        img: JusteMesDroits,
        name: 'Juste Mes Droits',
        description: 'Juste mes Droits.',
        socialLinks: {
            facebook: 'https://facebook.com/justemesdroits',
            twitter: 'https://twitter.com/justemesdroits',
            youtube: 'https://youtube.com/justemesdroits',
            instagram: 'https://instagram.com/justemesdroits',
        },
    },
    {
        id: 6,
        img: manbassa,
        name: 'Man Bassa',
        description: 'Communicateur basketball, créateur de contenus visuels. Services sur les réseaux sociaux Sérigraphe, infographe et entrepreneur.',
        socialLinks: {
            facebook: 'https://facebook.com/manbassa',
            twitter: 'https://twitter.com/manbassa',
            youtube: 'https://youtube.com/manbassa',
            instagram: 'https://instagram.com/manbassa',
        },
    },
    {
        id: 7,
        img: CoachingMentalPerform,
        name: 'Coaching Mental Perform',
        description: "Afin d'optimiser la performance de vos équipes en compétition tout en favorisant le plaisir dans leur pratique...",
        socialLinks: {
            facebook: 'https://facebook.com/coachingmentalperform',
            twitter: 'https://twitter.com/coachingmentalperform',
            youtube: 'https://youtube.com/coachingmentalperform',
            instagram: 'https://instagram.com/coachingmentalperform',
        },
    },
    {
        id: 8,
        img: DunkMagazine,
        name: 'Dunk Magazine',
        description: 'DUNK est le premier magazine essentiellement consacré au basketball local camerounais.',
        socialLinks: {
            facebook: 'https://www.facebook.com/share/eXQDqonCUYCpRPhF/?mibextid=LQQJ4d',
            twitter: 'https://twitter.com/dunkmagazine',
            youtube: 'https://youtube.com/dunkmagazine',
            instagram: 'https://www.instagram.com/dunkmagazine?igsh=N3FpdTgzZnIyeHdq',
        },
    },
    {
        id: 9,
        img: part8,
        name: 'Sport Around the World',
        description: 'Sport Around the World.',
        socialLinks: {
            facebook: 'https://facebook.com/sportaroundtheworld',
            twitter: 'https://twitter.com/sportaroundtheworld',
            youtube: 'https://www.youtube.com/watch?v=p61C051fXvs&t=2209s',
            instagram: 'https://instagram.com/sportaroundtheworld',
        },
    },
    {
        id: 10,
        img: part9,
        name: 'Mfandena Hotel',
        description: 'Mfandena Hotel.',
        socialLinks: {
            facebook: 'https://facebook.com/mfandenahotel',
            twitter: 'https://twitter.com/mfandenahotel',
            youtube: 'https://www.youtube.com/channel/UCzAbbPgd1mwza0CTk2N9B5Q',
            instagram: 'https://instagram.com/mfandenahotel',
        },
    },
    {
        id: 11,
        img: part1,
        name: 'Sport Rama',
        description: 'About Sport Rama.',
        socialLinks: {
            facebook: 'https://facebook.com/sportrama',
            twitter: 'https://twitter.com/sportrama',
            youtube: 'https://youtube.com/sportrama',
            instagram: 'https://instagram.com/sportrama',
        },
    },
    {
        id: 12,
        img: part10,
        name: 'Ligue De Basketball Du Centre',
        description: 'Ligue De Basketball Du Centre.',
        socialLinks: {
            facebook: 'https://facebook.com/liguedebasketballducentre',
            twitter: 'https://twitter.com/liguedebasketballducentre',
            youtube: 'https://www.youtube.com/channel/UCzAbbPgd1mwza0CTk2N9B5Q',
            instagram: 'https://instagram.com/liguedebasketballducentre',
        },
    },
    {
        id: 13,
        img: part11,
        name: 'Ligue De Basketball Du Mfoundi',
        description: 'Ligue De Basketball Du Mfoundi.',
        socialLinks: {
            facebook: 'https://facebook.com/liguedebasketballdumfoundi',
            twitter: 'https://twitter.com/liguedebasketballdumfoundi',
            youtube: 'https://www.youtube.com/channel/UCzAbbPgd1mwza0CTk2N9B5Q',
            instagram: 'https://instagram.com/liguedebasketballdumfoundi',
        },
    },
    {
        id: 14,
        img: part12,
        name: 'P2B SPORT & CONSULTING',
        description: 'P2B SPORT & CONSULTING.',
        socialLinks: {
            facebook: 'https://facebook.com/p2bsportconsulting',
            twitter: 'https://twitter.com/p2bsportconsulting',
            youtube: 'https://www.youtube.com/channel/UCzAbbPgd1mwza0CTk2N9B5Q',
            instagram: 'https://instagram.com/p2bsportconsulting',
        },
    },
    {
        id: 15,
        img: part15,
        name: 'Internal Growth',
        description: 'Internal Growth.',
        socialLinks: {
            facebook: 'https://facebook.com/internalgrowth',
            twitter: 'https://twitter.com/internalgrowth',
            youtube: 'https://www.youtube.com/channel/UCzAbbPgd1mwza0CTk2N9B5Q',
            instagram: 'https://instagram.com/internalgrowth',
        },
    },
    {
        id: 16,
        img: part16,
        name: 'Virgyn Travel Agency',
        description: 'Voyagez, vivez, offrez-vous le monde...',
        socialLinks: {
            facebook: 'https://facebook.com/virgyntravelagency',
            twitter: 'https://twitter.com/virgyntravelagency',
            youtube: 'https://www.youtube.com/channel/UCzAbbPgd1mwza0CTk2N9B5Q',
            instagram: 'https://instagram.com/virgyntravelagency',
        },
    },
    // {
    //     id: 17,
    //     img: GWLifeStyle,
    //     name: 'GW LifeStyle',
    //     description: "CONCEPTION ET PRODUCTION DE CADEAUX PERSONNALISÉS,PERSONALISATION D'OBJETS...",
    //     socialLinks: {
    //         facebook: 'https://www.facebook.com/profile.php?id=61560858431871 ',
    //         twitter: 'https://twitter.com/virgyntravelagency',
    //         youtube: 'https://youtube.com/virgyntravelagency',
    //         instagram: 'https://www.instagram.com/gwlifestyle0905?igsh=MWNqNm55M29ua3Fmag==',
    //     },
        
    // },
    {
        id: 18,
        img: elango,
        name: 'EaN',
        description: 'Capture Every Moment with a Clic...',
        socialLinks: {
            facebook: 'https://facebook.com/virgyntravelagency',
            twitter: 'https://twitter.com/virgyntravelagency',
            youtube: 'https://youtube.com/virgyntravelagency',
            instagram: 'https://instagram.com/virgyntravelagency',
        },
        
    },
    {
        id: 19,
        img: jaf,
        name: 'JAF',
        description: "Jeanne D'arc Foundation...",
        socialLinks: {
            facebook: 'https://facebook.com/virgyntravelagency',
            twitter: 'https://twitter.com/virgyntravelagency',
            youtube: 'https://www.youtube.com/watch?v=PmDpL9TvIKY&t=4s',
            instagram: 'https://instagram.com/virgyntravelagency',
        },
        
    },
    {
        id: 20,
        img: pan,
        name: 'PRO ATHLETE',
        description: 'PRO ATHLETE...',
        socialLinks: {
            facebook: 'https://facebook.com/virgyntravelagency',
            twitter: 'https://twitter.com/virgyntravelagency',
            youtube: 'https://youtube.com/virgyntravelagency',
            instagram: 'https://instagram.com/virgyntravelagency',
        },
        
    },
];
