/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState} from 'react'
import logo from  "../../../assets/logo.png"
import {MenuItems} from "./MenuItems"
import { HashLink as Link } from 'react-router-hash-link'
import "./header.scss"
import {AiOutlineAlignRight,AiOutlineClose} from 'react-icons/ai';


function Header() {
  const[isOpen, setisOpen]= useState(false);

  const toggleMenu=() => {
    setisOpen(!isOpen);
  }
  return (
    <header className='NavbarItems'>

        <div className='navbar-logo'>
          <img src={logo} alt='logo' width="60" />
        </div>
        <div className="menus-icons" onClick={toggleMenu}>
          {isOpen ? <AiOutlineClose/> :<AiOutlineAlignRight/>}
           
        </div>
        <ul className= {isOpen ? "nav-menu active" :"nav-menu"}>
                    {MenuItems.map((item, index)=>{
                        return(
                            <li className='navItem' key={index}>
                            <Link  className={item.cName} to={item.url} smooth>
                            <i className={item.icon}> </i>
                                {item.titre}</Link>    
                            </li>
                        )
                    })} 
                        </ul>
   </header>
    // <section className='navBarSection'>
    //                <div className='header'>  
    //                <div className='logoDiv'>  
    //                <a href='/' className='logo'>  
    //                <img src={logo} height="auto" width="150px" alt="heroImg"/>

    //                </a>  
    //                </div>  
    //                <div className={menu}> 
    //                <ul className= "navLists flex">
    //                 {MenuItems.map((item, index)=>{
      
    //                     return(
    //                         <li className='navItem' key={index}>
    //                         <NavLink  className={item.cName} to={item.url}>
    //                         <i className={item.icon}> </i>
    //                             {item.titre}</NavLink>    
    //                         </li>
    //                     )
    //                 })} 
    //                     </ul>
    //                     <div onClick={removeNav} className='closeNavbar'> 
    //                     <AiFillCloseCircle className='icon'/> 
    //                     </div> 
    //                </div>  

    //                <div onClick={showNav} className='toggleNavbar'> 
    //                     <TbGridDots className='icon'/> 
    //                     </div> 
    //                </div>  
      
    //      {/* <div className='menu-icons' onClick={changeIcons}>  
    //      <i className={menu ? "fas fa-times" : "fas fa-bars"}>  

    //      </i>  

    //      </div>  

    //      <ul className={menu ? "nav-menu actives" : "nav-menu"}>
    // {MenuItems.map((item, index)=>{
    //     return(
    //         <li key={index}>
    //         <NavLink  className={item.cName} to={item.url}>
    //            <i className={item.icon}> </i>
    //             {item.titre}</NavLink>    
    //         </li>
    //     )
    // })} 
    //     </ul> */}
    // </section>


  )
}

export default Header
