import React from "react";
import "./hero.css";
import first from "../../assets/une.png";
import second from "../../assets/hero_image_back.png";
import { motion } from "framer-motion";
import NumberCounter from "number-counter";
function Hero() {
  const transition = { type: "spring", duration: 3 };
  return (
    <div className="hero">
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <div className="the-best-ad">
          <motion.div
            initial={{ left: "180px" }}
            whileInView={{ left: "90px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>
          <span>Le Tournoi Des Go By BasketZoom </span>
        </div>
        <div className="hero-text">
          <div>
            <span className="stroke-text">Ensemble</span>
            <span>avec</span>
          </div>

          <div className="go">
            <span>Les GO</span>
          </div>
          <div>
            <span>
              Le « Tournoi des Go » est une compétition sportive de Basketball
              de 20 jours durant lesquels s’affronteront 100 jeunes filles
              venues des 10 régions du Cameroun. Il
              a été créé dans le but de permettre aux athlètes jeunes féminins de
              bénéficier d’une compétition qui leur est consacrée, Afin de palier le manque
              flagrant de tournoi spécifique.
            </span>
          </div>
        </div>
        <div className="figures">
          <div>
            <span>
              <NumberCounter end={25} start={0} delay="4" preFix="+" />
            </span>
            <span>Coachs</span>
          </div>
          <div>
            <span>
              <NumberCounter end={100} start={50} delay="4" preFix="+" />
            </span>
            <span>Go</span>
          </div>
          <div>
            <span>
              <NumberCounter end={25} start={10} delay="4" preFix="+" />
            </span>
            <span>Ateliers</span>
          </div>
        </div>
      </div>
      <div className="right-h">
        <img src={first} alt="" className="hero-image" />
        <motion.img
          initial={{ right: "11rem" }}
          whileInView={{ right: "35rem" }}
          transition={transition}
          src={second}
          alt=""
          className="hero-image-back"
        />
        {/* <motion.div
    initial={{right: "37rem"}}
    whileInView={{right: "28rem"}}
    transition={transition}
    className="calories">
        <img src={Calories} alt="" />
        <div>
            <span>
            Nombre de jours restant
            </span>
            <span>
            <NumberCounter end={41} start={10} delay='4' preFix="+" />
                
            </span>
        </div>
    </motion.div> */}
      </div>
    </div>
  );
}

export default Hero;
