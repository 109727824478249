import React from "react";
import "./accueil.css";

function ContactForm(){
    return<div className="right-j">
    <form action="" className="email-container">
      <input
        type="email"
        name="user_email"
        placeholder="Entrez votre email"
        id=""
      />
      <button className="btn btn-j">Nous rejoindre</button>
    </form>
  </div>
}

export default ContactForm;