import React from 'react'
import "./footer.css"
import Github from "../../../assets/github.png"
import Ig from "../../../assets/instagram.png"
import lin from "../../../assets/linkedin.png"
import Logo from "../../../assets/logo.png"

function Footer() {
  return (
<div className="Footer-container">
    <hr />
    <div className="footer">
        <div className="social-links">
        <img src={Github} alt="" />
        <img src={Ig} alt="" />
        <img src={lin} alt="" />
        </div>

    <div className="logo-f" style={{display: "block",textAlign: "center"}}>
        <img src={Logo} alt="" />
        <div>
        <span className='stroke-text'>IT-GRAFIK</span>

        </div>
    </div>

    </div>
    <div className="blur blur-f-1"></div>
    <div className="blur blur-f-2"></div>
</div>
  )
}

export default Footer
