import React, { useEffect, useRef, useState } from "react";
import "./apropos.css";
import { useTransform, useScroll, motion } from "framer-motion";
import Lenis from "@studio-freight/lenis";

const images = ["1.JPG", "2.JPG", "3.JPG", "4.JPG","5.JPG", "6.JPG", "7.JPG", "8.JPG"];


export default function About() {

  const ref = useRef(null);
  const [dimension, setDimension] = useState({width:0, height:0});

  const { scrollYProgress }  = useScroll({
    target: ref,
    offset: ['start end', 'end start']
  });
  const { height } = dimension;

  const y = useTransform(scrollYProgress, [0, 1], [0, height * 0.5])
  const y2 = useTransform(scrollYProgress, [0, 1], [0, height * 0.733])
  const y3 = useTransform(scrollYProgress, [0, 1], [0, height * 0.225])
  const y4 = useTransform(scrollYProgress, [0, 1], [0, height * 0.6])

  useEffect(() => {
    const lenis = new Lenis();
    const raf = (time)=>{
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    const resize = () => {
        setDimension({width: window.innerWidth, height: window.innerHeight})
      }
  
      window.addEventListener("resize", resize)
    requestAnimationFrame(raf);
    resize();
    return () => {
        window.removeEventListener("resize", resize);
      }
  },[]);
  return (
    <main className="main">
      <div ref={ref} className="gallerys">
        <Column images={[images[0], images[1], images[3]]} y={y}></Column>
        <Column images={[images[2], images[4], images[5]]} y={y2}></Column>
        <Column images={[images[7], images[2], images[6]]} y={y3}></Column>
        <Column images={[images[5], images[6], images[0]]} y={y4}></Column>
      </div>
    </main>
  );
}

const Column = ({ images, y }) => {

  return (
    <motion.div style={{y}} className="column">
      {images.map((image, index) => {
        return(
            <div className="imageContainer"  key={index}>
            <img className="img" src={`/images/${image}`} alt={image.alt} />
          </div>
        )
      }

      )}
    </motion.div>
  );
};
