import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/main.css";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import { AppProvider } from "./components/context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
        
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <AppProvider>
    <PrimeReactProvider>

      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
      </PrimeReactProvider>

    </AppProvider>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
