export const MenuItems= [
    {
        titre: "Accueil",
        url: "/",
        cName: "nav-links",
        icon: "fa-solid fa-house-user"
    },
    {
        titre: "Programmes",
        url: "/#programs",
        cName: "nav-links",
        icon: "fa-solid fa-briefcase"
    },
    {
        titre: "A propos",
        url: "/#apropos",
        cName: "nav-links",
        icon: "fa-solid fa-circle-info"
    },

    {
        titre: "Témoignages",
        url: "/#testimonial",
        cName: "nav-links",
        icon: "fa-solid fa-address-book"
    },
    {
        titre: "Partenaires",
        url: "/partners",
        cName: "nav-links",
        icon: "fa-solid fa-house-user"
    },
    // {
    //     titre: "Galerie",
    //     url: "/#gallerie",
    //     cName: "nav-links",
    //     icon: "fa-solid fa-address-book"
    // },
    {
        titre: "Inscriptions",
        url: "/inscriptions",
        cName: "nav-links",
        icon: "fa-solid fa-house-user"
    },
   
]