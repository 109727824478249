import React from 'react';
import { FaFacebook, FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa';

const facebookStyle = { color: '#1877F2' };
const twitterStyle = { color: '#1DA1F2' }; // Twitter brand color
const youtubeStyle = { color: '#FF0000' }; // YouTube brand color
const instagramStyle = { color: '#C13584' }; // Instagram brand color
const Partner = (props) =>{
    const {img, name, description,socialLinks} = props;
    return <article className='partner-logo'>
      <img src ={img} alt={name} />
      <h4>{name}</h4>
      <h2>{description}</h2>
      <div className="social-links">
        {socialLinks.facebook && (
          <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer" style={facebookStyle}>
            <FaFacebook />
          </a>
        )}
        {socialLinks.twitter && (
          <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer" style={twitterStyle}>
            <FaTwitter />
          </a>
        )}
        {socialLinks.youtube && (
          <a href={socialLinks.youtube} target="_blank" rel="noopener noreferrer" style={youtubeStyle}>
            <FaYoutube />
          </a>
        )}
        {socialLinks.instagram && (
          <a href={socialLinks.instagram} target="_blank" rel="noopener noreferrer" style={instagramStyle}>
            <FaInstagram />
          </a>
        )}
      </div>
    </article>
  }

  export default Partner;