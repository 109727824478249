import React from "react";
import "./accueil.css";
import Hero from "../layouts/hero";
import About from "../Apropos/apropos";
import Why from "../homeSection/why";
import Testimonial from "../homeSection/testimonial";
// import Partners from "../partners/partners";
import ContactForm from "./ContactForm";
import ProgramsSection from "./ProgramsSection";
function Accueil() {
  return (
    <div className="mt-6  md:mt-0 lg:mt-0 scroll-smooth">
      <Hero />
      <ProgramsSection/>

      
      <div className="Reasons">
        <Why />
      </div>
 
      <div className="plans-container">
        <div className="blur plans-blur-1"></div>
        <div className="blur plans-blur-2"></div>

        <div className="programs-header" id="apropos">
          <span className="stroke-text">ÊTES-VOUS</span>
          <span>Prête pour</span>
          <span className="stroke-text">L'aventure ?</span>
        </div>
        <div className="plans">
          <About />
        </div>
      </div>
      <div className="Testimonials" id="testimonial">
        <Testimonial />
      </div>

      {/* <div className="programs-header" id="gallerie">
        <span className="stroke-text">Quelques</span>
        <span>Images</span>
        <span className="stroke-text">Des Go</span>
      </div>
      <NewSearchForm />
      <NewGallery /> */}
      {/* <h1 className="text-3xl">Hello World</h1> */}
      {/*<Gallery />*/}
      <div className="join" id="join-us">
        <div className="left-j">
          <hr />
          <div>
            <span className="stroke-text">Nous </span>
            <span> suivre</span>
          </div>
          <div>
            <span>Toujours</span>
            <span className="stroke-text"> disponible?</span>
          </div>
        </div>
        <ContactForm />
      </div>
    </div>
  );
}

export default Accueil;
