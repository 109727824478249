import React from "react";
import "./accueil.css";
import Programmes from "../homeSection/programmes";

function ProgramsSection() {
    return (
      <div className="programs-section" id="programs">
        <div className="program-header">
          <span className="stroke-text">Explorez </span>
          <span className="stroke-text">Apprendre</span>
          <span className="stroke-text">s'améliorer </span>
        </div>
        <Programmes />
      </div>
    );
  }

  export default ProgramsSection