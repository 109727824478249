import React from 'react'
import { Partenaire } from '../../data/partenaire'
import "./partners.css"
import Partner from './Partner'

const Partners= ()=>{
  return <section className='partners'>
    { Partenaire.map((partner)=>{
      return <Partner {...partner} key={partner.id}/>
    })
    
  }
    
  </section>
}


// export default function Partners() {
//   return (
// //     <>
// //  <div className='imageCont'>

// //  { Partenaire.map((data, index)=>{
// //     return (
// //       <section className='partnerlist'>
// //         <div key={index} className="slider">
// //         <div className="slide-track">
// //             <div className="slide">
// //             <img src={data.image} alt="ok" />
// //             <div className="myparner">{data.name}</div>

// //             </div>
// //         </div>

// //     </div>
// //     </section>
// //     )

// //         })

// //     }
// //  </div>


// //   </>

//   )
// }
export default Partners