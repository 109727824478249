import image1 from "../assets/marie1.jpg";
import image3 from "../assets/img3.jpg";
import image2 from '../assets/logo.png'
export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'Marie Kenembeni',
    status : 'Joueuse'
  },
  {
    image: image2,
    review: "En tant que coach, je peaufine mes stratégies de jeu, et je favorise également une immersion totale dans le jeu chez les jeunes filles.",
    name: 'Gilles Mephu',
    status: 'COACH'
  },
  {
    image : image3,
    review:' Au quotidien, le tournoi de Go me permet de mieux communiquer sur le basketball, la promotion de la jeune fille est au coeur de nos preoccupation',
    name: 'Brenda',
    status: "BasketZoom"
  }
];
