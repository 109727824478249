import './App.css';
import { Route,Routes } from 'react-router-dom';
import Accueil from './components/Accueil/Accueil';
import Footer from './components/layouts/Footer/Footer';
import Header from './components/layouts/Hearder/Header';
import Inscriptions from './components/Inscriptions/inscriptions';
import Partners from './components/partners/partners';
function App() {

  return (
    <div className="App">
            <Header/>

       <Routes>
       <Route exact path="/" element={<Accueil/>}/>
       <Route exact path="/inscriptions" element={<Inscriptions/>}/>
       <Route exact path="/partners" element={<Partners/>}/>

       </Routes>

       <Footer/>

 
    </div>
  );
}

export default App;
