// import React,{useState} from "react";
// import { useForm } from "react-hook-form";
// import "./inscriptions.css";
// import { InputText } from "primereact/inputtext";
// import axios from 'axios';
// import { Message } from 'primereact/message';

// function Inscriptions() {

//     const { handleSubmit, register,reset , formState: { errors } } = useForm();
//     const [successMessage, setSuccessMessage] = useState('');
//     const [loading, setLoading] = useState(false);

//    async function  onSubmit(data) {
//         data.isValid=false;
//             try {
//               setLoading(true); 
//               await axios.post('https://teamapi.onrender.com/api/inscriptions', data);
//               setSuccessMessage('Inscriptions éffectuée avec succès!'); 
//              reset();
//             } catch (error) {
//               console.error('Erreur lors de l\'enregistrement des données :', error);
//             } finally {
//               setLoading(false); 
//             }
            
//         }
//     return (
//         <div className="inscriptions w-screen h-screen flex align-items-center justify-content-center mt-8  md:mt-0 lg:mt-0">
      
//             <form onSubmit={handleSubmit(onSubmit)} className="col-12 md:col-8 lg:col-8">
//             <div className="col-12 flex justify-content-center text-xl md:text-6xl lg:text-6xl mt-6">
//             <span className="stroke-text" >INSCRIPTIONS</span>
//              <span className="partner"> TOURNOI DES GO </span>
//             </div>
//                 <div className="grid col-12">
//                     <div className="col-12 text-center md:text-left lg:text-left md:col-6 lg:col-6">
//                         <label className="text-white font-normal text-xl" for="noms">Noms <span className="errors">*</span></label>

//                         <InputText className="col-12" id="noms" {...register("noms", {required: true})} />
//                         {errors.noms && <p className="errors">Le noms est obligatoire</p>}

//                     </div>
//                     <div className="col-12 text-center md:text-left lg:text-left md:col-6 lg:col-6">
//                         <label className="text-white font-normal text-xl" for="prenoms">Prénoms</label>

//                         <InputText className="col-12" id="prenoms" {...register("prenoms")} />
//                     </div>
//                     <div className="col-12 text-center md:text-left lg:text-left md:col-6 lg:col-6">
//                         <label className="text-white font-normal text-xl" for="numero">Numéro de Télephone<span className="errors">*</span></label>

//                         <InputText type="number" className="col-12" id="numero" {...register("numero", {required: true})} />
//                         {errors.numero && <p className="errors">Le numéro de téléphone est obligatoire</p>}
//                     </div>
//                     <div className="col-12 text-center md:text-left lg:text-left md:col-6 lg:col-6">
//                         <label className="text-white font-normal text-xl" for="email">Email</label>

//                         <InputText type="email" className="col-12" id="email" {...register("email")} />
//                     </div>
//                     <div className="col-12 text-center md:text-left lg:text-left md:col-6 lg:col-6">
//                         <label className="text-white font-normal text-xl" for="age">Âge <span className="errors">*</span></label>

//                         <InputText type="number" className="col-12" min="0" id="age" {...register("age", {required: true})} />
//                         {errors.age && <p className="errors">L'âge est obligatoire</p>}

//                     </div>
//                     <div className="col-12 text-center md:text-left lg:text-left md:col-6 lg:col-6">
//                         <label className="text-white font-normal text-xl" for="taille">Taille<span className="errors">*</span></label>

//                         <InputText type="number"  className="col-12" min="1.5"  id="taille" {...register("taille", {required: true})} />
//                         {errors.taille && <p className="errors">La taille est obligatoire</p>}

//                     </div>
//                     <div className="col-12 text-center md:text-left lg:text-left md:col-6 lg:col-6">
//                         <label className="text-white font-normal text-xl" for="pointure">Pointure<span className="errors">*</span></label>

//                         <InputText type="number" className="col-12"  min="22"  id="pointure" {...register("pointure", {required: true})} />
//                         {errors.pointure && <p className="errors">La pointure est obligatoire</p>}

//                     </div>
//                     <div className="col-12 text-center md:text-left lg:text-left md:col-6 lg:col-6">
//                         <label className="text-white font-normal text-xl" for="club">Club <span className="errors">*</span></label>

//                         <InputText className="col-12" id="club" {...register("club", {required: true})} />
//                         {errors.club && <p className="errors">Le noms du club est obligatoire</p>}

//                     </div>

//                     <div className="col-12 text-center md:text-left lg:text-left md:col-6 lg:col-6">
//                         <label className="text-white font-normal text-xl" for="ville">Ville de Résidence <span className="errors">*</span></label>

//                         <InputText className="col-12" id="ville" {...register("ville", {required: true})} />
//                         {errors.ville && <p className="errors">La ville de résidence est obligatoire</p>}



//                     </div>

//                 </div>


//                 <div className="col-12 flex justify-content-center">
//                     <button type="submit"  disabled={loading} className="btn btn-j">Envoyer {loading &&  <i className="pi pi-spin pi-spinner"></i>} </button>

//                 </div>
//                 <div className="col-12 flex justify-content-center">
//                    {successMessage && <Message severity="success" text={successMessage} />}

//                 </div>
//             </form>

//         </div>
//     );
// }

// export default Inscriptions;


import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import axios from 'axios';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import "./inscriptions.css";


function Inscriptions() {
    const { handleSubmit, register, reset, formState: { errors } } = useForm();
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    async function onSubmit(data) {
        data.isValid = false;
        try {
            setLoading(true);
            await axios.post('https://vpsserveur.itgrafikadmin.fun/tournoi/api/inscriptions', data);
            setSuccessMessage('Inscriptions éffectuée avec succès!');
            reset();
        } catch (error) {
            console.error('Erreur lors de l\'enregistrement des données :', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="inscriptions-container">
            <form onSubmit={handleSubmit(onSubmit)} className="inscriptions-form">
                <div className="form-header">
                    <span className="stroke-text">PRE-INSCRIPTIONS</span>
                    <span className="partner">TOURNOI DES GO</span><br/>
                    <span className="font-effect-neon">* La liste des 100 participantes au Tournoi Des Go sera publié par le comité d'organisation </span><br/>
                    <span className="font-effect-neon">*la priorité sera donnée aux filles des régions autres que celle du centre</span><br/>
                    <span  className="font-effect-fire">* L'innovation de 2024 sera la première édition du Tournoi Des Go Elite: 40 participantes.</span>
                </div>
                <div className="form-grid">
                    {[
                        { label: "Noms", name: "noms", required: true },
                        { label: "Prénoms", name: "prenoms" },
                        { label: "Numéro de Télephone", name: "numero", required: true, type: "number" },
                        { label: "Email", name: "email", type: "email" },
                        { label: "Âge", name: "age", required: true, type: "number", min: 0 },
                        { label: "Taille(en centimétre)", name: "taille", required: true, type: "number",step: "any",min:100 },
                        { label: "Pointure", name: "pointure", required: true, type: "number", min: 22 },
                        { label: "Club", name: "club", required: true },
                        { label: "Ville de Résidence", name: "ville", required: true }
                    ].map(({ label, name, required, type = "text", min }) => (
                        <div className="form-field" key={name}>
                            <label className="form-label" htmlFor={name}>
                                {label} {required && <span className="errors">*</span>}
                            </label>
                            <InputText
                                type={type}
                                className={classNames({ 'p-invalid': errors[name] })}
                                id={name}
                                min={min}
                                {...register(name, { required })}
                            />
                            {errors[name] && <p className="errors">{`${label} est obligatoire`}</p>}
                        </div>
                    ))}
                </div>
                <div className="form-actions">
                    <Button type="submit" label="Envoyer" loading={loading} className="btn-j" />
                </div>
                {successMessage && (
                    <div className="success-message">
                        <Message severity="success" text={successMessage} />
                    </div>
                )}
            </form>
        </div>
    );
}

export default Inscriptions;
